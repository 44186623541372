import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoLight from "../components/images/logo-light"

const IndexPage = () => (
  <Layout noHeader noFooter>
    <SEO title="Home" />
    <div className="h-screen w-screen bg-brand flex items-center justify-center">
      <div className="w-1/3">
        <LogoLight />
      </div>
    </div>
  </Layout>
)

export default IndexPage
